import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Newsletter Section */}
                <div className="footer-newsletter">
                    <div className="newsletter-label">
                        <h4>Stay in the loop and sign up for the Wardiere newsletter:</h4>
                    </div>
                    <form className="newsletter-form">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            aria-label="Email for newsletter subscription"
                            required
                        />
                        <button type="submit">Subscribe</button>
                    </form>
                </div>

                {/* Company Links Section */}
                <div className="footer-links">
                    <h4>Company</h4>
                    <a href="#about">About</a>
                    <a href="#solutions">Solutions</a>
                    <a href="#pricing">Pricing</a>
                    <a href="#team">Team</a>
                </div>

                {/* Support Links Section */}
                <div className="footer-links">
                    <h4>Support</h4>
                    <a href="#contact">Contact</a>
                    <a href="#faq">FAQ</a>
                    <a href="#privacy-policy">Privacy Policy</a>
                </div>

                {/* Social Media Links Section */}
                <div className="footer-links">
                    <h4>Follow Us</h4>
                    <a href="#instagram">Instagram</a>
                    <a href="#youtube">YouTube</a>
                    <a href="#twitter">Twitter</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
