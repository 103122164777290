import "./About.css"
const About = ()=>{
    return <div className="About-page">
        <div className="About-list">
            <div className="list-element" style={{backgroundColor:"#02a53e"}}><span>01</span><span className="list-content">About Us</span></div>
            <div className="list-element" style={{backgroundColor:"#029338"}}><span>02</span><span className="list-content">Market Analysis</span></div>
            <div className="list-element" style={{backgroundColor:"#02742c"}}><span>03</span><span className="list-content">Goals & Strategy</span></div>
            <div className="list-element" style={{backgroundColor:"#016024"}}><span>04</span><span className="list-content">Content plan</span></div>
            <div className="list-element" style={{backgroundColor:"#01531f"}}><span>05</span><span className="list-content">Budget</span></div>

        </div>
        <div className="About-content">
        <span>About Us.</span>
        <div>
            <h3>introduction</h3>
            <p>Welcome to Rimberio Co., a leading force in the real estate sector. Our commitment to reshaping urban living defines our
                essence. With a focus on innovation and quality, Rimberio Co. is dedicated to creating residential and commercial
                spaces that surpass expectations, merging functionality with aesthetics.</p>
            <div className="About-ph-vi">
                <div className="About-Philosophy"><h3>Our Philosophy</h3><p>Driven by a profound philosophy,
                Rimberio Co. sees real estate as a means to enhance lives and communities. Our team of professionals collaborates to deliver
                 projects marked by sustainable practices, modern design, and technological innovation. Customer satisfaction is paramount
                  as we strive to create spaces that cater to the unique needs of our clients.</p></div>
                <div className="About-vision"><h3>Vision for the future</h3><p>Looking ahead, Rimberio Co. aspires to expand globally, leaving a lasting impact on
                    skylines and neighborhoods. Committed to ethical practices and transparency, we aim to continually redefine industry standards. Join us
                     in shaping the future of real estate, where each project embodies innovation, sustainability, and enduring quality.</p></div>
            </div>
            </div>
        </div>
    </div>
}

export default About