import "./Work.css"
const Work = ()=>{
    return <div className="work-page">
        <div className="work-trade">
        <div className="work-container">

<div className="work-container-ul"><ul><li>Skirts</li><li>Dresses</li><li>Sunglasses</li></ul>
<ul><li>Tops</li><li>Mom Jeans</li><li>Boots</li></ul>
<ul><li>Belts</li><li>Earrings</li><li>Swimsuits</li></ul>
<ul><li>Belts</li><li>Earrings</li><li>Swimsuits</li></ul>
</div>
        </div>
        </div>
        <div className="work-structure">
            <div className="work-element">
                <h4>DREAMTRADE</h4>
            <div className="dreamTrade-element">
                <p>25K</p>

            </div>
            <div className="trade-button">
        Buy
        <span className="icon">&#10230;</span>
        </div>
            </div>
            <div className="work-element">
            <h4>DREAMTRADE</h4>
            <div className="dreamTrade-element">

                <p>50K</p>

            </div>
            <div className="trade-button">
        Buy
        <span className="icon">&#10230;</span>
        </div>
            </div>
            <div className="work-element">
            <h4>DREAMTRADE</h4>
            <div className="dreamTrade-element">

                <p>100K</p>

            </div>
            <div className="trade-button">
        Buy
        <span className="icon">&#10230;</span>
        </div>
            </div>
            <div className="work-element">
            <h4>DREAMTRADE</h4>
            <div className="dreamTrade-element">

                <p>200K</p>

            </div>
            <div className="trade-button">
        Buy
        <span className="icon">&#10230;</span>
        </div>
            </div>
        </div>
    </div>
}

export default Work
