import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <header>
            <div id="navbar-container">
                <div id="navbar-content">
                    <div id="navbar-main">
                        <div id="title-section">
                            <div id="title-container">
                                <div id="title-text">
                                    <p id="title-main">
                                        <span id="title-dream">DREAM</span>
                                        <span id="title-arabic">حلمة</span>
                                        <span id="title-trade">TRADE</span><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <nav className="header-nav">
                            <ul>
                                <li><Link className="list-nav" to="/">Home</Link></li>
                                <li><Link className="list-nav" to="/Work">Work</Link></li>
                                <li><Link className="list-nav" to="/About">About Us</Link></li>
                                <li><Link className="list-nav" to="/Product">Product</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
