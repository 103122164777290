import BootLogo from "../Home/boot.svg"
import "./Product.css"
const Product = ()=>{
    return <div className="product-page">
        <div className="search-filter">
        <div className="search-container">
        <span><i className="fas fa-search search-icon"></i></span>
        <input type="text" placeholder="CHERCHER"/>
    </div>
        <span className="filter-para">   <i className="fas fa-sliders-h filter"></i> Filter</span>
        </div>
        <div className="product-structure">
            <div className="product-element">
            <div className="boot-element">
                <img src={BootLogo} alt="boot" />
                <h1>Boot Name</h1>
                <p>GET [BOT NAME], THE ULTIMATE AUTOMATED TRADING SOLUTION. USES ARTIFICIAL INTELLIGENCE TO ANALYZE MARKETS AND EXECUTE
                     TRADES AUTOMATICALLY, WITH ADVANCED RISK MANAGEMENT TOOLS. EASY TO CUSTOMIZE AND 98% SUPPORT</p>
            </div>
            <div className="buy-button">
            <i className="fas fa-shopping-cart"></i>
        Buy now

        </div>
            </div>
            <div className="product-element">
            <div className="boot-element">
                <img src={BootLogo} alt="boot" />
                <h1>Boot Name</h1>
                <p>GET [BOT NAME], THE ULTIMATE AUTOMATED TRADING SOLUTION. USES ARTIFICIAL INTELLIGENCE TO ANALYZE MARKETS AND EXECUTE
                     TRADES AUTOMATICALLY, WITH ADVANCED RISK MANAGEMENT TOOLS. EASY TO CUSTOMIZE AND 98% SUPPORT</p>
            </div>
            <div className="buy-button">
            <i className="fas fa-shopping-cart"></i>
        Buy now

        </div>
            </div>
            <div className="product-element">
            <div className="boot-element">
                <img src={BootLogo} alt="boot" />
                <h1>Boot Name</h1>
                <p>GET [BOT NAME], THE ULTIMATE AUTOMATED TRADING SOLUTION. USES ARTIFICIAL INTELLIGENCE TO ANALYZE MARKETS AND EXECUTE
                     TRADES AUTOMATICALLY, WITH ADVANCED RISK MANAGEMENT TOOLS. EASY TO CUSTOMIZE AND 98% SUPPORT</p>
            </div>
            <div className="buy-button">
            <i className="fas fa-shopping-cart"></i>
        Buy now

        </div>
            </div>
            <div className="product-element">
            <div className="boot-element">
                <img src={BootLogo} alt="boot" />
                <h1>Boot Name</h1>
                <p>GET [BOT NAME], THE ULTIMATE AUTOMATED TRADING SOLUTION. USES ARTIFICIAL INTELLIGENCE TO ANALYZE MARKETS AND EXECUTE
                     TRADES AUTOMATICALLY, WITH ADVANCED RISK MANAGEMENT TOOLS. EASY TO CUSTOMIZE AND 98% SUPPORT</p>
            </div>
            <div className="buy-button">
            <i className="fas fa-shopping-cart"></i>
        Buy now

        </div>
            </div>
        </div>
    </div>
}

export default Product