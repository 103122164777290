import "./HomePage.css";
import IntroLogo from "./intro.svg";
import Trade from "./trade.svg";
import Boot from "./boot.svg";
import ServiceLogo from "./service.gif";
import BrandLogo from "./brand.svg";

const HomePage = () => {
    return (
        <div className="home-page">
            <div className="home-page-intro">
                <div className="img-container-intro">
                    <img src={IntroLogo} alt="intro" />
                </div>
                <div className="intro">
                    <h1>Introduction</h1>
                    <h3>Company Overview</h3>
                    <div className="para-intro">
                        <p>
                            Presentations are communication tools that can be used as demonstrations, lectures,
                            speeches, reports, and more. Most of the time, they’re presented before an audience.
                            It serves a variety of purposes, making them powerful tools for convincing and teaching.
                        </p>
                    </div>
                </div>
            </div>
            <div className="home-page-trade">
                <div className="trade">
                    <h1>Trading Challenge</h1>
                    <div className="para-trade">
                        <p>
                            Get a trading challenge account without any conditions. Try your strategies freely,
                            and evaluate your performance in a real trading environment. Continuous technical
                            support and flexibility of use. Start now and achieve your goals with confidence!
                        </p>
                    </div>
                    <div className="more-button">
                        More
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
                <div className="img-container-trade">
                    <img src={Trade} alt="trade" />
                </div>
            </div>
            <div className="home-page-boot">
                <div className="img-container-boot">
                    <img src={Boot} alt="boot" />
                </div>
                <div className="boot">
                    <h1>Boot Name</h1>
                    <div className="para-boot">
                        <p>
                            Get [bot name], the ultimate automated trading solution. Uses artificial intelligence to
                            analyze markets and execute trades automatically, with advanced risk management tools.
                            Easy to customize and 98% support.
                        </p>
                    </div>
                    <div className="more-button">
                        More
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
            </div>
            <div className="home-page-service">
                <div className="service">
                    <h1>Service name</h1>
                    <div className="para-service">
                        <p>
                            Take advantage of [service name] to develop a trading robot according to your own
                            strategies. Create your robot, test it, and activate it on your favorite platforms. We
                            provide you with easy tools and continuous technical support to ensure success.
                        </p>
                    </div>
                    <div className="more-button">
                        More
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
                <div className="img-container-service">
                    <img src={ServiceLogo} alt="service" />
                </div>
            </div>
            <div className="home-page-brand">
                <div className="img-container-brand">
                    <img src={BrandLogo} alt="brand" />
                </div>
                <div className="brand">
                    <h1>Brand Name</h1>
                    <div className="para-brand">
                        <p>
                            [Brand Name] offers clothing that combines style with the world of trading. Choose from
                            distinctive, high-quality designs that reflect your passion for trading. Shop now and add
                            a professional touch to your wardrobe!
                        </p>
                    </div>
                    <div className="more-button">
                        More
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
            </div>
            <div className="home-page-market">
                <div className="img-container-market">
                    <div className="img-container-market-users">
                        <h1>500k+</h1>
                        <p>Active Users</p>
                    </div>
                    <div className="img-container-market-plan">
                        <div>
                            <h1>350k+</h1>
                            <p>Free Plan</p>
                        </div>
                        <div>
                            <h1>150K+</h1>
                            <p>Pro Plan</p>
                        </div>
                    </div>
                </div>
                <div className="market">
                    <h1>Market Size</h1>
                    <div className="para-market">
                        <p>
                            Presentations are communication tools that can be used as demonstrations, lectures,
                            speeches, reports, and more.
                        </p>
                    </div>
                </div>
            </div>
            <div className="offer">
                <div className="offer-free">
                    <h3>Free</h3>
                    <h1>FREE</h1>
                    <p>forever free for individuals and small teams up to 3</p>
                    <ul>
                        <li>Unlimited Access</li>
                        <li>100MB Storage</li>
                        <li>3 Users</li>
                        <li>Unlimited Tasks</li>
                    </ul>
                    <div className="offer-button">
                        Start for free
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
                <div className="offer-Unlimited">
                    <h3>Unlimited</h3>
                    <h1>$29</h1>
                    <p>per user/month billed yearly<br /> $39 billed monthly</p>
                    <ul>
                        <li>All in Free +</li>
                        <li>Unlimited Guests</li>
                        <li>Priority Support</li>
                        <li>Smart Notifications</li>
                    </ul>
                    <div className="offer-button">
                        Get Unlimited
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
                <div className="offer-Enterprise">
                    <h3>Enterprise</h3>
                    <h1>$35</h1>
                    <p>per user/month billed yearly<br />$45 billed monthly</p>
                    <ul>
                        <li>All in Unlimited +</li>
                        <li>Bulk Export</li>
                        <li>Advanced Analytics</li>
                        <li>Advanced Security</li>
                    </ul>
                    <div className="offer-button">
                        Get Enterprise
                        <span className="icon">&#10230;</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
